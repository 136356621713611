@font-face {
	font-family: MyriadArabic;
	src: url(/fonts/MyriadArabic-Regular.otf);
	padding: 2px 0px;
}

body 
{
	margin: 0 !important;
	width: 380px;
	height: 240px;
	background-repeat: no-repeat;
	font-family: MyriadArabic,"Droid Arabic Naskh", Arial;
	direction: rtl;
}

.logo {
	direction:ltr;
}
.footer {
	direction:ltr;
	margin:5px 0px 0px 5px;
}
.footer a{
	color:#333333;
	font-size:17px;
	text-decoration:none;
}
.footer a:hover{
	text-decoration: underline;
}
.container
{
	width: 380px;
	height: 240px;
}

.menu
{
	height: 24px;
	width: 100%;
	color: #ABACAE;
	font-size: 18px;
}

.menu div
{
	cursor: pointer;
	position: relative;
	height: calc(100% - 2px);
	min-width: 50px;
	display: inline-block;
	z-index: 5;
	text-align: center;
	top: 1px;
	margin: 0px 10px;
	padding: 0px 10px;
}

.menu div.selected
{
	border: 1px solid #ABACAE;
	border-bottom: 0px;
	background-color: #F2F8FD;
	color: #0388DD;
}

.chart
{
	width: calc(100% - 2px);
	height: 110px;
	position: relative;
	top: -1px;
	border: 1px solid #ABACAE;
	border-bottom: 0px;
	background-color:#F2F8FD;
	z-index: 1;
}

.rows
{
	position: relative;
	width: calc(100% - 2px);
	height: 100px;
	border: 1px solid #ABACAE;
	border-top: 0px;
	padding-top: 3px;
}

.row 
{
	cursor: pointer;
	width: 100%;
	height: 23px;
	margin-bottom: 1px;
}

.row.selected
{
	background-color: #F2F8FD;
}

.col {
	display: inline-block;
	height: 21px;
	padding-top: 2px;
	min-width: 10px;
	text-align: center;
	color: #787979;
	vertical-align: top;
}

.col-chg-percent {
	width: 58px;
	direction:ltr;
}

.col-arrows {
	width: 26px;
}

.col-chg {
	width: 60px;
	direction:ltr;
}

.col-last,.col-high,.col-low {
	width: 50px;
}

.col-name {
	width: 66px;
}

.col-symbol {
	width: 26px;
	margin: 0px 8px;
}
.col-title
{
	width: 95px;
	text-align: right;
	padding-right: 14px;
}

.col-name.data
{
	text-align: right;
}

.col-arrows.data.up 
{
	background: url("/images/icons.png") 4px 48px;
}

.col-arrows.data.down
{
	background: url("/images/icons.png") 4px 22px;
}

.col-chg.data.up,.col-chg-percent.data.up
{
	color: #41ce65;
}

.col-chg.data.down,.col-chg-percent.data.down
{
	color: #ff2f3a;
}

.col-last.data.BgRed {
	background-color: #ff2f3a;/*#FFDDE0;*/
	color:#FFFFFF;
	width: 50px;
	height: 21px;
	/*margin: 2px 14px 8px 14px;*/
}
.col-last.data.BgGreen {
	background-color: #41ce65;/*#E1F6E8;*/
	color:#FFFFFF;
	width: 50px;
	height: 21px;
	/*margin: 2px 14px 8px 14px;*/
}

.col-symbol.data.eu 
{
	background: url("/images/icons.png") 1px 0px;
}

.col-symbol.data.jd
{
	background: url("/images/icons.png") 1px -24px;
}

.col-symbol.data.lb
{
	background: url("/images/icons.png") 1px -49px;
}

.col-symbol.data.dl
{
	background: url("/images/icons.png") 1px -73px;
}
.col-symbol.data.gold
{
	background: url("/images/icons.png") 1px -138px;
}
.col-symbol.data.oil
{
	background: url("/images/icons.png") 1px -189px;
}
.col-symbol.data.silver
{
	background: url("/images/icons.png") 1px -209px;
}
.col-symbol.data.brent
{
	background: url("/images/icons.png") 1px -163px;
}
.col-symbol.data.us
{
	background: url("/images/icons.png") 1px -232px;
}
.col-symbol.data.gb
{
	background: url("/images/icons.png") 1px -256px;
}
.col-symbol.data.eurusd
{
	background: url("/images/icons.png") 0px -287px;
}
.col-symbol.data.gbpusd
{
	background: url("/images/icons.png") 0px -308px;
}
.col-symbol.data.usdcad
{
	background: url("/images/icons.png") 0px -330px;
}
.col-symbol.data.usdjpy
{
	background: url("/images/icons.png") 0px -353px;
}
